import React, { useEffect, useState } from "react";

import "./related-keywords.scss";
import useFetch from "../../lib/useFetch";
import Card from "../Card";

type RelatedKeywordsProps = {
	keyword: string;
};

const RelatedKeywords = ({ keyword }: RelatedKeywordsProps) => {
	const { loading, error, result } = useFetch("/trends/related", "node", {
		keyword,
	});
	const [data, setData] = useState([]);

	useEffect(() => {
		if (result?.default?.rankedList) {
			let lists = result.default.rankedList;
			setData(lists[lists.length - 1].rankedKeyword);
		}
	}, [result]);

	return (
		<Card
			error={error}
			className="related-keywords"
			title="Gerelateerde zoektermen"
			loading={loading}
		>
			<ul>
				{data.map((item: any, index: number) => (
					<li className="rank-item" key={item.query}>
						<span className="rank-item-index">{index + 1}:</span>
						<a
							href={`https://trends.google.nl${item.link}`}
							target="_blank"
							rel="noopener noreferrer"
							className="rank-item-label"
						>
							{item.query}
						</a>
						<b>{item.value}</b>
					</li>
				))}
			</ul>
		</Card>
	);
};

export default RelatedKeywords;

import { createAction, handleActions } from "redux-actions";

export const toggleItem = createAction("TOGGLE_ITEM");
export const resetItems = createAction("RESET_ITEMS");

// export const logout = createAction("REMOVE_PROFILE");

const emptyCompareState = {
	items: []
};

const compareReducer = handleActions(
	{
		TOGGLE_ITEM: (state: any, action: any) => {
			let exist = state.items.find(
				(i: any) => i.id === action.payload.id
			);
			let newItems = state.items;
			if (exist) {
				newItems = newItems.filter(
					(i: any) => i.id !== action.payload.id
				);
			} else {
				newItems = [...newItems, action.payload];
			}

			return {
				...state,
				items: newItems
			};
		},
		RESET_ITEMS: (state: any, action: any) => {
			return {
				...emptyCompareState
			};
		}
	},
	{
		...emptyCompareState
	}
);
export default compareReducer;

import React from "react";
import "./product-detail.scss";
import Layout from "../../components/Layout";
import useFetch from "../../lib/useFetch";
import Gallery from "../../components/Gallery";
import Button from "../../components/Button";
import ProductPrice from "../../components/ProductPrice";
import ProductsGraph from "../../components/ProductsGraph";
import ProductCard from "../../components/Compare/ProductCard";
import SeoList from "../../components/SeoList";
import RelatedKeywords from "../../components/RelatedKeywords";
import KeywordGraph from "../../components/KeywordGraph";
import AddToCart from "../../components/AddToCart";

const ProductDetailView = (props: any) => {
	const id = props.match.params.id;
	const { result, loading, error } = useFetch(`/products/${id}`);
	let product = result.data ? result.data : {};
	let {
		media,
		info,
		vendor,
		thumbnail,
		category,
		cross_sells,
		seo,
	} = product;

	if (media && media.length) {
		media = media.map((i: any) => i.link);
	} else {
		media = [thumbnail];
	}

	let price = info ? info[0] : {};
	return (
		<Layout
			errorText={error ? "Product niet gevonden" : undefined}
			loading={loading}
			title={loading ? "" : product.title}
			goBack
		>
			<div className="product-detail-view">
				{media && <Gallery images={media} />}
				<div className="product-info card">
					<h2>{product.title}</h2>
					{price && price.price && (
						<ProductPrice
							type="big"
							label="Adviesprijs"
							info={price}
						/>
					)}
					<p className="description">{product.description}</p>
					<div className="attributes">
						<div className="attributes-item">
							<span className="label">Best verkocht</span>
							<span className="value">
								{price?.is_bestseller ? "Ja" : "Nee"}
							</span>
						</div>
						<div className="attributes-item">
							<span className="label">Rating</span>
							<span className="value">
								{price?.rating || "Niet gevonden"}
							</span>
						</div>
						<div className="attributes-item">
							<span className="label">Voorraad</span>
							<span className="value">
								{price?.is_in_stock
									? "Op voorraad"
									: "Uitverkocht"}
							</span>
						</div>
						<div className="attributes-item">
							<span className="label">Leverancier</span>
							<span className="value">
								{vendor?.name || "Niet gevonden"}
							</span>
						</div>
						<div className="attributes-item">
							<span className="label">Categorie</span>
							<span className="value">
								{category?.title || "Niet gevonden"}
							</span>
						</div>
					</div>

					<AddToCart id={product.id} />

					<div className="buttons">
						<Button href={product.link}>
							Bekijk product pagina
						</Button>
					</div>
				</div>
				{info && info.length ? (
					<ProductsGraph products={[product]} />
				) : (
					false
				)}
				<KeywordGraph keyword={product.title} />

				{product.title && <RelatedKeywords keyword={product.title} />}

				{seo && seo.length ? <SeoList data={seo} /> : false}

				{cross_sells && cross_sells.length ? (
					<div className="card cross_sells">
						<h2>Bekijk ook</h2>
						<div className="products">
							{cross_sells.map((p: any) => (
								<ProductCard
									key={p.id + "product index cs"}
									{...{ ...p }}
								/>
							))}
						</div>
					</div>
				) : (
					false
				)}
			</div>
		</Layout>
	);
};

export default ProductDetailView;

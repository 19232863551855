import React, { useState, useRef } from "react";
import "./dropdown.scss";
import useOutsideClose from "../../lib/useOutsideClose";
type DropdownOption = {
	id: number | null;
	text: string;
};

type DropdownProps = {
	options: DropdownOption[];
	value: any | null;
	setValue: (id: any | null) => void;
	placeholder?: string;
};

const Dropdown = ({ options, value, setValue, placeholder }: DropdownProps) => {
	const [visible, setVisible] = useState(false);
	const dropdownRef = useRef(null);
	useOutsideClose(dropdownRef, setVisible);
	let v = value ? Number(value) : null;

	let current = options.find(
		(option: DropdownOption) => option.id === v
	) as DropdownOption;

	return (
		<div
			ref={dropdownRef}
			className={`dropdown ${visible ? "dropdown-open" : ""}`}
		>
			<div onClick={() => setVisible(!visible)} className="dropdown-head">
				<span className="label">{current?.text || placeholder}</span>
				<span className="icon">
					<i className={`fal fa-angle-down`}></i>
				</span>
			</div>
			<div className="dropdown-body">
				{options.map((option: DropdownOption) => (
					<div
						onClick={() => {
							setValue(option.id);
							setVisible(false);
						}}
						key={option.id + option.text}
						className={`dropdown-option ${
							option.id === value ? "dropdown-option_active" : ""
						}`}
					>
						<span className="label">{option.text}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default Dropdown;

import React, { useState } from "react";
import "./users-view.scss";
import Layout from "../../../components/Layout";
import useFetch from "../../../lib/useFetch";
import Button from "../../../components/Button";
import api from "../../../api";

const UsersView = () => {
	const { loading, result, setResult } = useFetch("/users");
	const [fetching, setFetching] = useState(0);

	const handleConfirm = (id: number) => {
		setFetching(id);
		api.admin.user
			.confirm(id)
			.then(() => {
				setResult({
					...result,
					data: result.data.map((item: any) => {
						if (item.id === id) {
							return {
								...item,
								is_confirmed: true,
							};
						}
						return item;
					}),
				});
			})
			.catch(() => alert("Something went wrong"))
			.finally(() => setFetching(0));
	};

	return (
		<Layout loading={loading} className="users-view" title="Gebruikers">
			<div className="users-view-grid">
				{result?.data?.length
					? result.data.map((item: any) => (
							<div
								key={item.id + "User"}
								className="users-view-item"
							>
								<p className="info">
									<b>Naam: </b> {item.full_name}
								</p>
								<p className="info">
									<b>Bedrijf: </b> {item.company}
								</p>
								<p className="info">
									<b>Email: </b>{" "}
									<a href={`mailto: ${item.email}`}>
										{item.email}
									</a>
								</p>
								<p className="info">
									<b>Categorieën:</b>
									{item.categories.length
										? item.categories
												.map((item: any) => item.title)
												.join(", ")
										: "Gebruiker heeft geen categorieën"}
								</p>
								<div className="buttons">
									{!item.is_confirmed && (
										<Button
											onClick={() =>
												handleConfirm(item.id)
											}
											loading={fetching === item.id}
										>
											Toegang geven
										</Button>
									)}
									<Button
										type="gray"
										to={`/admin/users/${item.id}`}
									>
										Pas gebruiker aan
									</Button>
								</div>
							</div>
					  ))
					: false}
			</div>
		</Layout>
	);
};

export default UsersView;

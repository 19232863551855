import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
import Layout from "../../../components/Layout";
import LogHead from "./LogHead";
import "./logs-view.scss";
import moment from "moment";
import Button from "../../../components/Button";
import api from "../../../api";

const LogsView = () => {
	const [active, setActive] = useState("");
	const [logs, setLogs] = useState({} as { [key: string]: any[] });

	useEffect(() => {
		const socket = socketIOClient(process.env.REACT_APP_NODE_URL as string);
		try {
			socket.on("updated logs", (data: any) => {
				if (data) {
					setLogs(data);
				}
			});
		} catch (e) {
			setLogs({});
		}
	}, []);

	const handleStart = () => {
		api.admin.logs.start(active);
	};

	return (
		<Layout title="Logs">
			<div className="logs">
				<LogHead {...{ active, setActive }} />
				<div className="logs-body">
					{logs[active] && logs[active].length ? (
						logs[
							active
						].map(
							(log: {
								text: string;
								type: string;
								created_at: string;
							}) => (
								<Log
									key={log.text + log.type + log.created_at}
									text={log.text}
									type={log.type}
									created_at={log.created_at}
								/>
							)
						)
					) : (
						<Button onClick={handleStart} type="white">
							Start importing
						</Button>
					)}
				</div>
			</div>
		</Layout>
	);
};

const Log = ({
	text,
	type,
	created_at,
}: {
	key: string;
	text: string;
	type: string;
	created_at: string;
}) => {
	return (
		<div className={`log ${"log-" + type}`}>
			<span className="log-content">{text}</span>
			<span className="log-date">
				{moment(created_at).format("HH:mm:ss")}
			</span>
		</div>
	);
};

export default LogsView;

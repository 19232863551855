import axios from "axios";

const nodeUrl = process.env.REACT_APP_NODE_URL;

export default {
	logs: () => {
		return axios.get(`${nodeUrl}/logs`);
	},
	start: (key: string) => {
		return axios.get(`${nodeUrl}/start/${key}`);
	},
};

import React, { useState } from "react";
import { motion } from "framer-motion";
import "./register-view.scss";
import Input from "../../../components/Form/Input";
import Button from "../../../components/Button";
import Background from "../../../components/Background";
import api from "../../../api";
import store from "../../../store";
import { setUser as setReduxUser } from "../../../reducers/user";

const CardVariants = {
	start: {
		opacity: 0,
		y: -200,
		scale: 1.2,
	},
	end: {
		opacity: 1,
		y: 0,
		scale: 1,
	},
};
const RegisterView = () => {
	const [fetching, setFetching] = useState(false);
	const [status, setStatus] = useState("");
	const [user, setUser] = useState({
		email: "",
		first_name: "",
		affix: "",
		last_name: "",
		company: "",
		password: "",
		passwordRepeat: "",
	});

	const handleUser = (key: string, value: string) => {
		setUser({
			...user,
			[key]: value,
		});
	};

	const handleRegister = () => {
		if (
			!user.first_name ||
			!user.last_name ||
			!user.email ||
			!user.company ||
			!user.password ||
			!user.passwordRepeat
		) {
			setStatus("Vul alle verplichte velden in");
			return;
		}

		if (user.password !== user.passwordRepeat) {
			setStatus("Wachtwoorden komen niet overeen");
			return;
		}
		setFetching(true);
		api.auth
			.register(user)
			.then(({ data }: any) => {
				if (data.id) {
					store.dispatch(setReduxUser(data));
				}
			})
			.catch((e) => {
				const text = e?.response?.data?.error;
				setStatus(text || "Something went wrong");
			})
			.finally(() => setFetching(false));
	};

	return (
		<Background className="register-view">
			<motion.div
				variants={CardVariants}
				initial="start"
				animate="end"
				className="card"
				transition={{
					type: "spring",
					stiffness: 260,
					damping: 100,
				}}
			>
				<h1>Aanmelden</h1>
				{status && <p>{status}</p>}
				<Input
					onChange={(val) => handleUser("email", val)}
					value={user.email}
					type="email"
					placeholder="E-mail"
				/>
				<Input
					onChange={(val) => handleUser("first_name", val)}
					value={user.first_name}
					placeholder="Voornaam"
				/>
				<Input
					onChange={(val) => handleUser("affix", val)}
					value={user.affix}
					placeholder="Tussenvoegsel (optioneel)"
				/>
				<Input
					onChange={(val) => handleUser("last_name", val)}
					value={user.last_name}
					placeholder="Achternaam"
				/>
				<Input
					onChange={(val) => handleUser("company", val)}
					value={user.company}
					placeholder="Bedrijfsnaam"
				/>
				<Input
					onChange={(val) => handleUser("password", val)}
					value={user.password}
					placeholder="Wachtwoord"
					type="password"
				/>
				<Input
					onChange={(val) => handleUser("passwordRepeat", val)}
					value={user.passwordRepeat}
					placeholder="Wachtwoord herhalen"
					type="password"
				/>
				<div className="buttons">
					<Button to="/" type="gray">
						Annuleren
					</Button>
					<Button loading={fetching} onClick={handleRegister}>
						Aanmelden
					</Button>
				</div>
			</motion.div>
		</Background>
	);
};

export default RegisterView;

import React from "react";
import "./background.scss";
import background from "../../assets/images/background.jpg";

const Background = ({ children, className }: any) => {
	return (
		<div
			style={{
				backgroundImage: `url(${background})`,
			}}
			className={`background ${className || ""}`}
		>
			<div className="container">{children}</div>
		</div>
	);
};

export default Background;

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.common = {
	"X-Requested-With": "XMLHttpRequest",
};

axios.interceptors.request.use((config) => {
	if (localStorage.getItem("token")) {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
	}
	return config;
});

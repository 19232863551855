import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "./lib/bootstrap";
import Loading from "./components/Loading";
import PriceCompareView from "./views/PriceCompareView";
import CategoryView from "./views/CategoryView";
import OverViewView from "./views/OverviewView";
import VendorProductsView from "./views/VendorProductsView";
import ProductDetailView from "./views/ProductDetailView";
import LoginView from "./views/Auth/LoginView";
import PasswordForgotView from "./views/Auth/PasswordForgotView";
import RegisterView from "./views/Auth/RegisterView";
import api from "./api";
import BestsellersView from "./views/BestsellersView";
import ArchiveView from "./views/ArchiveView";
import ConfirmView from "./views/ConfirmView";
import store from "./store";
import { setUser } from "./reducers/user";
import UsersView from "./views/Admin/UsersView";
import LogsView from "./views/Admin/LogsView";
import UserDetailView from "./views/Admin/UserDetailView";
import MergeCategoryView from "./views/Admin/MergeCategoryView";
import CartsView from "./views/Admin/CartsView";
import CartsDetailView from "./views/Admin/CartsView/detail";
import CartCollectionView from "./views/CartCollectionView";
import CartCreateView from "./views/CartCreateView";

const AdminAuthProtect = ({ component: Component, auth, ...rest }: any) => (
	<Route
		{...rest}
		auth={auth}
		render={(props: any) =>
			auth.id && auth.is_admin ? (
				<Component {...props} />
			) : (
				<Redirect to="/" />
			)
		}
	/>
);

const BasicAuthProtect = ({ component: Component, auth, ...rest }: any) => (
	<Route
		{...rest}
		auth={auth}
		render={(props: any) =>
			!auth.id ? <Redirect to="/" /> : <Component {...props} />
		}
	/>
);

const Routes = (props: any) => {
	const [fetching, setFetching] = useState(true);
	const currentUser = useSelector((state: any) => state.user);
	const [auth, setAuth] = useState({
		id: null,
		is_confirmed: false,
		full_name: "",
	});

	useEffect(() => {
		setAuth(currentUser);
	}, [currentUser]);

	useEffect(() => {
		if (localStorage.getItem("token")) {
			api.auth
				.profile()
				.then((user: any) => {
					setAuth(user);
					store.dispatch(setUser(user));
					setFetching(false);
				})
				.catch(() => {
					setFetching(false);
				});
		} else {
			setFetching(false);
		}
	}, []);

	if (fetching) {
		return <Loading type="black" />;
	}

	if (auth.id && !auth.is_confirmed) {
		return <ConfirmView full_name={auth.full_name} />;
	}

	return (
		<Router>
			<Switch>
				<Route exact path="/" component={LoginView} />
				<Route
					exact
					path="/forgot/:token?"
					component={PasswordForgotView}
				/>
				<Route exact path="/register" component={RegisterView} />
				<Route
					exact
					path="/collecties/:slug"
					component={CartCollectionView}
				/>
				<BasicAuthProtect
					auth={auth}
					exact
					path="/categories"
					component={CategoryView}
				/>

				{/* <Route exact path="/products" component={ArchiveView} /> */}
				<BasicAuthProtect
					auth={auth}
					exact
					path="/search/:search"
					component={ArchiveView}
				/>
				<BasicAuthProtect
					auth={auth}
					exact
					path="/products/:category/:page?"
					component={ArchiveView}
				/>
				<BasicAuthProtect
					auth={auth}
					exact
					path="/bestsellers/:pVendor?/:page?"
					component={BestsellersView}
				/>
				<BasicAuthProtect
					auth={auth}
					exact
					path="/compare-price"
					component={PriceCompareView}
				/>
				<BasicAuthProtect
					auth={auth}
					exact
					path="/vendor/:id"
					component={VendorProductsView}
				/>
				<BasicAuthProtect
					auth={auth}
					exact
					path="/product/:id"
					component={ProductDetailView}
				/>
				<AdminAuthProtect
					auth={auth}
					exact
					path="/admin/dashboard"
					component={OverViewView}
				/>

				<AdminAuthProtect
					auth={auth}
					exact
					path={"/admin/users"}
					component={UsersView}
				/>
				<AdminAuthProtect
					auth={auth}
					exact
					path={"/admin/users/:id"}
					component={UserDetailView}
				/>
				<AdminAuthProtect
					auth={auth}
					exact
					path={"/admin/logs"}
					component={LogsView}
				/>
				<AdminAuthProtect
					auth={auth}
					exact
					path={"/admin/merge-categories"}
					component={MergeCategoryView}
				/>
				<AdminAuthProtect
					auth={auth}
					exact
					path={"/admin/carts"}
					component={CartsView}
				/>
				<AdminAuthProtect
					auth={auth}
					exact
					path={"/admin/carts/:slug"}
					component={CartsDetailView}
				/>
				<AdminAuthProtect
					auth={auth}
					exact
					path={"/cart"}
					component={CartCreateView}
				/>
			</Switch>
		</Router>
	);
};

export default connect((state: any) => ({}), {})(Routes);

import React, { useState } from "react";
import "./gallery.scss";
import Image from "../Image";

const Gallery = ({ images }: { images: string[] }) => {
	let [active, setActive] = useState(0);
	return (
		<div className="gallery card">
			<div className="gallery-box">
				{images && images.length && images[active] && (
					<Image src={images[active]} alt="" />
				)}
			</div>
			<div className="gallery-nav">
				{images &&
					images.length &&
					images.map((url, index) => (
						<Image
							className={`${index === active && "active"}`}
							src={url}
							key={url + index}
							alt=""
							onClick={() => setActive(index)}
						/>
					))}
			</div>
		</div>
	);
};

export default Gallery;

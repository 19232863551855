import React from "react";
import "./input.scss";
type InputProps = {
	type?: string;
	value?: string;
	onChange: (text: string) => void;
	placeholder?: string;
	label?: string;
	name?: string;
	required?: boolean;
	className?: string;
};

const Input = ({
	type,
	value,
	onChange,
	placeholder,
	label,
	name,
	required,
	className,
}: InputProps) => {
	return (
		<div className={`input ${className || ""}`}>
			<label>
				{label || ""}
				<input
					type={type || "text"}
					value={value || ""}
					onChange={(e: any) => onChange(e.target.value)}
					placeholder={placeholder || ""}
					name={name}
					required={required}
				/>
			</label>
		</div>
	);
};

export default Input;

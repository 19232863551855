import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../components/Layout";
import "./bestsellers-view.scss";
import api from "../../api";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import ProductCard from "../../components/Compare/ProductCard";
import VendorDropDown from "../../components/VendorDropDown";

const BestsellersView = (props: any) => {
	let { pVendor, page } = props.match.params;
	const [vendor, setVendor] = useState(pVendor as any);
	const [fetching, setFetching] = useState(true);
	const [data, setData] = useState([]);
	const [meta, setMeta] = useState({} as any);
	const history = useHistory();

	const getProducts = useCallback(async () => {
		if (!vendor) {
			setFetching(false);
			return;
		}
		setFetching(true);
		api.products
			.listBestsellers({
				page: page || 1,
				vendor: vendor as number,
			})
			.then(({ data }) => {
				setFetching(false);
				setData(data.data);
				setMeta(data.meta);
			})
			.catch((e: any) => {
				console.log(e);
				setFetching(false);
			});
	}, [page, vendor]);

	useEffect(() => {
		getProducts();
		return () => {};
	}, [page, getProducts]);

	useEffect(() => {
		if (vendor !== Number(pVendor)) {
			history.push(`/bestsellers/${vendor}/1`);
		}
		if (!vendor || vendor === "null") {
			history.push("/bestsellers");
		}

		if (page > 1) {
			history.push(`/bestsellers/${vendor}/1`);
		} else {
			getProducts();
		}

		return () => {};
	}, [vendor, getProducts, page, history, pVendor]);

	const navigateTo = (direction: string) => {
		let target =
			direction === "next"
				? meta.current_page + 1
				: meta.current_page - 1;

		history.push(`/bestsellers/${vendor}/${target}`);
	};

	const Pagination = () => {
		if (!data || !data.length) {
			return <div></div>;
		}
		return (
			<div className="pagination">
				<span
					onClick={() => navigateTo("prev")}
					className={`
								pagination-item
								${meta.current_page === 1 ? "pagination-item-disable" : ""}
							`}
				>
					Vorige
				</span>
				<span
					onClick={() => navigateTo("next")}
					className={`
								pagination-item
								${meta.current_page === meta.last_page ? "pagination-item-disable" : ""}
							`}
				>
					Volgende
				</span>
			</div>
		);
	};

	return (
		<Layout
			loading={fetching}
			title={"Bestsellers"}
			button={
				<VendorDropDown
					emptyPlaceholder="Kies een leverancier"
					value={vendor}
					setValue={setVendor}
				/>
			}
		>
			<Pagination />
			<div className="products">
				{data.length
					? data.map((item: any) => (
							<ProductCard
								key={item.id + "product index"}
								{...{ ...item }}
							/>
					  ))
					: false}
			</div>
			<Pagination />
		</Layout>
	);
};

export default connect(
	(state: any) => ({
		items: state.compare.items,
	}),
	{}
)(BestsellersView);

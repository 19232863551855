import React from "react";
import { useParams } from "react-router";
import Layout from "../../../components/Layout";
import useFetch from "../../../lib/useFetch";
import api from "../../../api";

const CartDetailView = () => {
	const { slug } = useParams();
	const { loading, result, error, setResult } = useFetch(`/carts/${slug}`);
	let cart = result.data;

	const handleRemoveProduct = (id: number) => {
		let products = cart.products.filter((i: any) => i.id !== id);
		api.admin.cart
			.patch(
				cart.id,
				products.map((i: any) => i.id)
			)
			.then(() =>
				setResult({
					...result,
					data: {
						...result.data,
						products,
					},
				})
			);
	};

	return (
		<Layout
			errorText={error ? "Collectie niet gevonden" : ""}
			loading={loading}
			title={cart?.name || ""}
		>
			<div className="carts-detail-view">
				<ul>
					{cart &&
						cart.products &&
						cart.products.map((item: any) => (
							<li key={item.id + "product"}>
								{item.title}

								<i
									onClick={() => handleRemoveProduct(item.id)}
									className="fal fa-trash"
								></i>
							</li>
						))}
				</ul>
			</div>
		</Layout>
	);
};

export default CartDetailView;

import React from "react";
import "./statistics.scss";
import useFetch from "../../lib/useFetch";

function kFormatter(num: number) {
	let divided: number = Math.abs(num) / 1000;
	divided = divided.toFixed(1) as any;
	return Math.abs(num) > 999
		? Math.sign(num) * divided + "k"
		: Math.sign(num) * Math.abs(num);
}

const Statistics = () => {
	const { result, loading } = useFetch("/statistics");

	const renderCountAdded = (key: string) => {
		if (loading) {
			return <i className="fal fa-spin fa-spinner-third" />;
		}
		let added = result && result.added ? result.added : {};
		return <h2 className="count">{added[key] || 0}</h2>;
	};

	const renderCountTotal = (key: string) => {
		if (loading) {
			return <i className="fal fa-spin fa-spinner-third" />;
		}
		let total = result && result.total ? result.total : {};
		return <h2 className="count">{kFormatter(total[key]) || 0}</h2>;
	};

	return (
		<>
			<div className="statistics">
				<h2>
					Statistieken <small>vandaag toegevoegd</small>
				</h2>
				<div className="grid">
					<div className="card">
						{renderCountAdded("products")}
						<p className="label">Producten</p>
					</div>
					<div className="card">
						{renderCountAdded("prices")}
						<p className="label">Prijzen</p>
					</div>
					<div className="card">
						{renderCountAdded("categories")}
						<p className="label">Categorieen</p>
					</div>
					<div className="card">
						{renderCountAdded("vendors")}
						<p className="label">Leveranciers</p>
					</div>
				</div>
			</div>
			<div className="statistics">
				<h2>
					Statistieken <small>totaal</small>
				</h2>
				<div className="grid">
					<div className="card">
						{renderCountTotal("products")}
						<p className="label">Producten</p>
					</div>
					<div className="card">
						{renderCountTotal("prices")}
						<p className="label">Prijzen</p>
					</div>
					<div className="card">
						{renderCountTotal("categories")}
						<p className="label">Categorieen</p>
					</div>
					<div className="card">
						{renderCountTotal("vendors")}
						<p className="label">Leveranciers</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Statistics;

import React from "react";
import "./product-price.scss";

const ProductPrice = ({
	info,
	label,
	type,
}: {
	info: any;
	label?: string;
	type?: string;
}) => {
	if (!info) {
		return <div />;
	}
	return (
		<div
			className={`product-price ${
				info.is_sale ? "product-price-sale" : ""
			}
                ${type ? "product-price_" + type : ""}
            `}
		>
			{label && <span className="label">{label}</span>}
			{info.is_sale && info.original_price && (
				<span className="original">&euro; {info.original_price}</span>
			)}
			{info.price && (
				<span className="price-active">&euro; {info.price}</span>
			)}
		</div>
	);
};

export default ProductPrice;

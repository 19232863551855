import React from "react";
import "./categories.scss";
import useFetch from "../../lib/useFetch";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";

const Categories = () => {
	const { loading, result } = useFetch("/categories");
	const { category } = useParams();
	const data = result.data || [];
	return (
		<div className="categories-filter">
			{!loading && data.length
				? [
						{ slug: "alle-producten", title: "Alle producten" },
						...data,
				  ].map((item: any) => (
						<NavLink
							className={`${
								category === item.slug ? "active" : ""
							}`}
							key={item.slug + item.title}
							to={`/products/${item.slug}/1`}
						>
							<span className="label">{item.title || ""}</span>
						</NavLink>
				  ))
				: false}
		</div>
	);
};

export default Categories;

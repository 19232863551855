import React, { useState } from "react";
import useFetch from "../../lib/useFetch";
import "./filter.scss";

type Filter = {
	vendor: number;
	category: number;
	is_bestseller: boolean;
	[key: string]: any;
};

type FilterProps = {
	filter: Filter;
	setFilter: (filter: Filter) => void;
};

const Filter = ({ filter, setFilter }: FilterProps) => {
	return (
		<div className="filter">
			<VendorFilter
				title="Leveranciers"
				name="vendor"
				route="/vendors"
				{...{ filter, setFilter }}
			/>
			<VendorFilter
				title="Categorieen"
				name="category"
				route="/categories"
				{...{ filter, setFilter }}
			/>
		</div>
	);
};

type FilterListProps = {
	filter: Filter;
	setFilter: (filter: Filter) => void;
	name: string;
	route: string;
	title: string;
};

const VendorFilter = ({
	filter,
	setFilter,
	name,
	route,
	title,
}: FilterListProps) => {
	let query = route;
	const [open, setOpen] = useState(false);
	const { result, loading } = useFetch(query);
	let data = result?.data || [];
	let options = data.map((item: any) => ({
		id: item.id,
		text: item.name ? item.name : item.title,
	}));

	const handleChange = (id: number) => {
		setFilter({ ...filter, [name]: filter[name] === id ? 0 : id });
	};

	return (
		<div className="filter-item">
			<label>
				{title}
				<span onClick={() => setOpen(!open)} className="more">
					{open ? "Minder" : "Meer"} tonen
				</span>
			</label>
			<OptionList
				handleChange={handleChange}
				options={options}
				value={filter[name]}
				open={open}
			/>
		</div>
	);
};

const OptionList = ({
	options,
	handleChange,
	value,
	open,
}: {
	options: any[];
	handleChange: (i: any) => void;
	value: any;
	open: boolean;
}) => {
	return (
		<ul className={`${open ? "open" : ""}`}>
			{options.map((item: any) => (
				<li
					onClick={() => handleChange(item.id)}
					key={item.id + item.text}
				>
					<span>{item.text}</span>
					<div
						onClick={() => handleChange(item.id)}
						className={`checkbox ${
							item.id === value ? "checkbox-active" : ""
						}`}
					>
						<i className="fal fa-check"></i>
					</div>
				</li>
			))}
		</ul>
	);
};

export default Filter;

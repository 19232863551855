import React, { useState } from "react";
import api from "../../api";
import Input from "../Form/Input";
import Button from "../Button";

const CreateCart = ({ onCreate }: { onCreate: (item: any) => void }) => {
	const [name, setName] = useState("");
	const [fetching, setFetching] = useState(false);

	const handleSubmitName = (e?: any) => {
		if (e) {
			e.preventDefault();
		}
		if (!name) {
			return;
		}
		setFetching(true);
		api.admin.cart
			.create(name)
			.then(({ data }: any) => {
				onCreate(data.data);
				setName("");
			})
			.catch((e) => {
				console.log({
					e,
				});
				alert("Er is iets misgegaan");
			})
			.finally(() => setFetching(false));
	};

	return (
		<form className="cart-form" onSubmit={handleSubmitName}>
			<Input placeholder="Naam" value={name} onChange={setName} />
			<Button loading={fetching} onClick={handleSubmitName}>
				Winkelwagen aanmaken
			</Button>
		</form>
	);
};

export default CreateCart;

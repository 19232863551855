import React from "react";
import Layout from "../../components/Layout";
import "./archive-view.scss";
import Categories from "../../components/Categories";
import Products from "../../components/Products";
import { useParams } from "react-router";
import SearchBox from "../../components/SearchBox";

const ArchiveView = () => {
	const { search } = useParams();

	return (
		<Layout type="no-spacing">
			<Banner />
			<div className=" container archive-view">
				{search ? (
					<SearchBox type="big" value={search} />
				) : (
					<Categories />
				)}
				<Products search={search} />
			</div>
		</Layout>
	);
};

const url =
	"https://images.pexels.com/photos/279719/pexels-photo-279719.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";

const Banner = () => (
	<div className="banner">
		<img src={url} alt="" />
	</div>
);

export default ArchiveView;

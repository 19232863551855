import React from "react";
import { connect } from "react-redux";
import "./compare-nav.scss";
import store from "../../store";
import { toggleItem, resetItems } from "../../reducers/compare";
import { useHistory } from "react-router";

const CompareNav = (props: any) => {
	const items = props.compare.items;
	const history = useHistory();
	if (!items.length) {
		return <div></div>;
	}

	const handleClear = () => {
		store.dispatch(resetItems());
	};

	return (
		<div className="compare-nav">
			<div className="compare-nav-body">
				{items.length > 1 && (
					<div className="buttons">
						<div onClick={handleClear} className="clear">
							Leeg maken
						</div>
						<div
							onClick={() => history.push(`/compare-price`)}
							className="submit"
						>
							Vergelijk producten
						</div>
					</div>
				)}

				{items.map((item: any) => (
					<div
						key={item.id + "product-item"}
						className="product-item"
					>
						<img src={item.thumbnail} alt="" />
						<span className="label">{item.title}</span>
						<div
							onClick={() => store.dispatch(toggleItem(item))}
							className="icon"
						>
							<i className="fal fa-times"></i>
						</div>
					</div>
				))}
			</div>
			<div className="compare-nav-footer">
				<span className="label">
					{items.length} producten geselecteerd
				</span>
				<div className="icon">
					<i className="fal fa-angle-up"></i>
				</div>
			</div>
		</div>
	);
};

export default connect(
	(state: any) => ({
		compare: state.compare
	}),
	{}
)(CompareNav);

import React from "react";
import { useParams } from "react-router";
import background from "../../assets/images/background-flipped.jpg";
import useFetch from "../../lib/useFetch";
import NotFound from "../../components/NotFound";

import "./cart-collection-view.scss";
import moment from "moment";
import "moment/locale/nl";
import ProductPrice from "../../components/ProductPrice";

const CartCollectionView = () => {
	const { slug } = useParams();
	const { error, loading, result } = useFetch(`/carts/${slug}`);
	const cart = result?.data || {};
	if (error) {
		return (
			<div className="cart-collection-error">
				<NotFound />
			</div>
		);
	}

	if (loading) {
		return <div></div>;
	}

	return (
		<div className="cart-collection-view">
			<header
				style={{
					backgroundImage: `url(${background})`,
				}}
			>
				<div className="container">
					<h1>{cart.name || ""}</h1>
					{cart.created_at && (
						<h2>
							{moment(cart.created_at).fromNow()} aangemaakt door
							BusyBirds
						</h2>
					)}
					<h3>
						{cart.products.length}
						{cart.products.length === 1
							? " product"
							: " verschillende producten"}
					</h3>
				</div>
			</header>
			<ul>
				{cart.products?.map((item: any) => (
					<li key={item.title + item.id}>
						<div className="container">
							<div className="gallery">
								<img src={item.thumbnail} alt="" />
							</div>
							<div className="info">
								<h1>{item.title}</h1>
								<div className="info-price">
									<ProductPrice
										type="big"
										label="Andere boden dit product aan voor"
										info={item.info[0]}
									/>
								</div>
								<p>{item.description}</p>
								<div className="info-images">
									{item.media.length
										? item.media
												.splice(0, 6)
												.map((media: any) => (
													<div
														style={{
															backgroundImage: `url(${media.link})`,
														}}
														className="image"
													></div>
												))
										: false}
								</div>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

const url =
	"https://images.pexels.com/photos/279719/pexels-photo-279719.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";

const Banner = ({ name }: { name: string }) => (
	<div className="banner">
		<img src={url} alt="" />
		<div className="container">
			<div className="content">
				<h1>{name || ""}</h1>
			</div>
		</div>
	</div>
);

export default CartCollectionView;

import React from "react";
import "./seo-list.scss";

const SeoList = ({ data }: { data: any[] }) => {
	return (
		<div className="card seo-list">
			<h2>Seo positie</h2>
			<ul>
				{data.map((seo, index) => (
					<li
						key={seo.url}
						className={`${seo.is_vendor ? "is_vendor" : ""}`}
					>
						<b>{index + 1}</b>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={seo.url}
						>
							{seo.url}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default SeoList;

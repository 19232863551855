import React from "react";
import "./card.scss";

type CardProps = {
	children: any;
	type?: string;
	title?: string;
	loading?: boolean;
	className?: string;
	error?: any;
};

const Card = ({
	children,
	type,
	title,
	loading,
	className,
	error,
}: CardProps) => {
	if (error) {
		return (
			<div className={`card card-error ${className || ""}`}>
				<p>Er is iets misgegaan</p>
			</div>
		);
	}
	return (
		<div
			className={`card ${type ? "card-" + type : ""} ${className || ""}`}
		>
			{loading ? (
				<div className="card-loading">
					{title && (
						<div className="card-head">
							<span className="card-head-title">{title}</span>
						</div>
					)}
					<i className="fal fa-spinner-third fa-spin"></i>
				</div>
			) : (
				<>
					{title && (
						<div className="card-head">
							<span className="card-head-title">{title}</span>
						</div>
					)}
					{children}
				</>
			)}
		</div>
	);
};

export default Card;

import React, { useState, useEffect } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import Card from "../Card";
import useFetch from "../../lib/useFetch";
import "./keyword-graph.scss";

const KeywordGraph = ({ keyword }: { keyword: string }) => {
	const { result, error } = useFetch(`/trends`, "node", { keyword });
	const [data, setData] = useState({
		x: [],
		series: [],
	});
	useEffect(() => {
		if (result?.default?.timelineData) {
			let items = result.default.timelineData;
			let dates = items.map((item: any) =>
				moment(item.formattedAxisTime).format("DD-MM-YYYY")
			);
			let series = items.map((item: any) => [
				moment(item.formattedAxisTime).format("DD-MM-YYYY"),
				...item.value,
			]);

			setData({
				x: dates,
				series,
			});
		}
	}, [result]);

	return (
		<Card
			title="Keyword relevantie"
			error={error}
			className="keyword-graph"
		>
			<HighchartsReact
				highcharts={Highcharts}
				containerProps={{
					style: {
						width: "100%",
					},
				}}
				options={{
					title: {
						text: "",
					},
					xAxis: {
						categories: data.x,
					},
					series: {
						data: data.series,
						// type: "spline",
					},
				}}
			/>
		</Card>
	);
};

export default KeywordGraph;

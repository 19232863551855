import React, { useState, useEffect } from "react";
import _ from "lodash";
import "./cart-create-view.scss";
import Layout from "../../components/Layout";
import InfiniteScroll from "react-infinite-scroller";
import ProductCard from "../../components/Compare/ProductCard";
import api from "../../api";
import Button from "../../components/Button";
import Cart from "./Cart";
import Filter from "./Filter";

const CartCreateView = () => {
	const [cart, setCart] = useState({
		slug: "",
		product_ids: [] as number[],
		id: 0,
	});
	const [filter, setFilter] = useState({
		vendor: 0,
		category: 0,
		is_bestseller: false,
	});
	const [empty, setEmpty] = useState(false);
	const [data, setData] = useState([] as any[]);
	const [meta, setMeta] = useState({
		current_page: 1,
		total: 0,
		last_page: undefined,
	});
	let limit = 30;

	useEffect(() => {
		let slug = localStorage.getItem("selected-cart");
		if (slug) {
			api.admin.cart.show(slug).then(setCart);
		}
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchProducts(1);
	}, [filter]);

	const fetchProducts = (page: number) => {
		api.products.listAll(page, limit, filter).then((res: any) => {
			if (res.data) {
				let items = res.data.data;
				if (!items.length) {
					setEmpty(true);
				} else {
					setEmpty(false);
				}
				if (page === 1) {
					setData(items);
				} else {
					setData([...data, ...items]);
				}
			}
			setMeta(res.data.meta);
		});
	};

	const handleAddToCart = (product_id: number) => {
		if (!cart.id) {
			alert("U moet eers een winkelwagen selecteren");
			return;
		}
		let products = _.xor(cart.product_ids, [product_id]);
		api.admin.cart.patch(cart.id, products).then(({ data }) => {
			setCart(data.data);
		});
	};

	return (
		<Layout type="no-spacing">
			<div className="cart-create-view">
				<HideBar
					to={"left"}
					icon={<i className="fal fa-angle-right" />}
				>
					<Filter {...{ filter, setFilter }} />
				</HideBar>
				{empty ? (
					<div className="empty">
						<h2>
							Op dit moment zijn er geen producten voor uw zoek
							opdracht
						</h2>
					</div>
				) : (
					<div className="infinite">
						<InfiniteScroll
							pageStart={0}
							loadMore={(page: number) => fetchProducts(page)}
							hasMore={meta.current_page !== meta.last_page}
							loader={
								<div className="loader" key={0}>
									<i className="fal fa-spin fa-spinner-third"></i>
									<p>Een ogenblik geduld</p>
								</div>
							}
						>
							<div className="cart-create-view-grid">
								{data.length
									? data.map((item: any) => (
											<ProductCard
												className={
													cart.product_ids.includes(
														item.id
													)
														? "is-in-cart"
														: ""
												}
												key={item.id + "product index"}
												{...{ ...item }}
												button={
													<Button
														type={
															cart.product_ids.includes(
																item.id
															)
																? "primary"
																: "gray"
														}
														onClick={(e: any) => {
															if (
																e.stopPropagation
															) {
																e.stopPropagation();
															}
															handleAddToCart(
																item.id
															);
														}}
													>
														<i className="fal fa-shopping-cart"></i>
														{cart.product_ids.includes(
															item.id
														)
															? "Verwijderen"
															: "Toevoegen"}
													</Button>
												}
											/>
									  ))
									: false}
							</div>
						</InfiniteScroll>
					</div>
				)}
				<HideBar
					to={"right"}
					icon={<i className="fal fa-shopping-cart" />}
				>
					<Cart cart={cart} setCart={setCart} />
				</HideBar>
			</div>
		</Layout>
	);
};

const HideBar = ({ children, icon, to }: any) => {
	const [closed, setClosed] = useState(false);
	return (
		<div
			className={`hide-bar ${
				closed ? "hide-bar-closed" : ""
			} position-${to}`}
		>
			<div className="hide-bar-spacer"></div>
			<div className={`hide-bar-wrapper`}>
				{children}
				<div
					onClick={() => setClosed(!closed)}
					className="hide-bar-icon"
				>
					{!closed ? <i className={`fal fa-angle-${to}`}></i> : icon}
				</div>
			</div>
		</div>
	);
};

export default CartCreateView;

import React, { useState } from "react";
import "./add-to-cart.scss";
import useFetch from "../../lib/useFetch";
import { useSelector } from "react-redux";
import api from "../../api";
type AddToCartProps = {
	id: number;
};

const AddToCart = ({ id }: AddToCartProps) => {
	const auth = useSelector((state: any) => state.user);
	const { result, loading } = useFetch(`/carts?product_id=${id}`);
	if (!auth.is_admin) {
		return <></>;
	}

	return (
		<div className="add-to-cart">
			<p className="add-to-cart-title">Toevoegen aan winkelwagen</p>
			<ul className="add-to-cart-list">
				{result?.data?.map((item: any) => (
					<ListItem
						key={item.slug}
						name={item.name}
						id={item.id}
						product_id={id}
						products={item.product_ids}
					/>
				))}
			</ul>
		</div>
	);
};

const ListItem = ({
	name,
	id,
	product_id,
	products,
}: {
	name: string;
	id: number;
	product_id: number;
	products: number[];
}) => {
	const [value, setValue] = useState(products.includes(product_id));
	const handleProductsChange = () => {
		let items = [];
		if (value) {
			items = products.filter((i) => i !== product_id);
		} else {
			items = [...products, product_id];
		}
		api.admin.cart.patch(id, items).then(() => setValue(!value));
	};

	return (
		<li onClick={handleProductsChange}>
			<span>{name}</span>
			<input
				onChange={handleProductsChange}
				type="checkbox"
				checked={value}
			/>
		</li>
	);
};

export default AddToCart;

import React from "react";
import "./nav.scss";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import store from "../../../store";
import { resetUser } from "../../../reducers/user";
import { useSelector } from "react-redux";
import SearchBox from "../../SearchBox";
import { useParams } from "react-router";

const routes = {
	top: [
		{
			label: "Producten",
			icon: "fal fa-store",
			to: "/products/alle-producten",
			children: [
				{
					label: "Categorieen",
					icon: "fal fa-archive",
					to: "/categories",
				},
				{
					label: "Bestsellers",
					icon: "fal fa-star",
					to: "/bestsellers",
				},
			],
		},
		{
			label: "Vergelijken",
			icon: "fal fa-retweet",
			to: "/compare-price",
		},
	],
};

const handleLogout = async () => {
	await localStorage.setItem("token", "");
	store.dispatch(resetUser());
};

const Nav = () => {
	const currentUser = useSelector((state: any) => state.user);
	const params: any = useParams();
	return (
		<div className="nav">
			<div className="container">
				<div className="nav-wrapper">
					<NavLink to="/" className="nav-logo">
						<img src={logo} alt="" />
					</NavLink>
					<SearchBox type="small" value={params?.search || ""} />
				</div>

				<div className="nav-items nav-items_top">
					{routes.top.map((route) => (
						<div
							key={route.to}
							className={`nav-items-item ${
								route.children ? "has-children" : ""
							}`}
						>
							<NavLink
								activeClassName="nav-items-item_active"
								className="nav-items-item-link"
								to={route.to}
							>
								<div className="icon">
									<i className={route.icon}></i>
								</div>
								<span className="label">{route.label}</span>
							</NavLink>

							{route.children && route.children.length ? (
								<>
									<div className="icon icon-right">
										<i className="fal fa-angle-down"></i>
									</div>
									<div className="nav-children">
										{route.children.map((r) => (
											<NavLink
												activeClassName="nav-items-item_active"
												className="nav-items-item"
												key={r.to}
												to={r.to}
											>
												<div className="icon">
													<i className={r.icon}></i>
												</div>
												<span className="label">
													{r.label}
												</span>
											</NavLink>
										))}
									</div>
								</>
							) : (
								false
							)}
						</div>
					))}
					<NavLink
						activeClassName="nav-items-item_active"
						className="nav-items-item"
						to={"/cart"}
					>
						<div className="icon">
							<i className="fal fa-shopping-cart"></i>
						</div>
						<span className="label">Winkelwagen</span>
					</NavLink>
					{currentUser.is_admin && (
						<div className="nav-items-item has-children">
							<div className="icon">
								<i className="fal fa-user-secret"></i>
							</div>
							<span className="label">Admin</span>
							<div className="icon icon-right">
								<i className="fal fa-angle-down"></i>
							</div>
							<div className="nav-children">
								<NavLink
									activeClassName="nav-items-item_active"
									className="nav-items-item"
									to={"/admin/users"}
								>
									<div className="icon">
										<i className="fal fa-users"></i>
									</div>
									<span className="label">Gebruikers</span>
								</NavLink>
								<NavLink
									activeClassName="nav-items-item_active"
									className="nav-items-item"
									to={"/admin/dashboard"}
								>
									<div className="icon">
										<i className="fal fa-tachometer-slow"></i>
									</div>
									<span className="label">Dashboard</span>
								</NavLink>
								<NavLink
									activeClassName="nav-items-item_active"
									className="nav-items-item"
									to={"/admin/logs"}
								>
									<div className="icon">
										<i className="fal fa-terminal"></i>
									</div>
									<span className="label">Logs</span>
								</NavLink>
								<NavLink
									activeClassName="nav-items-item_active"
									className="nav-items-item"
									to={"/admin/merge-categories"}
								>
									<div className="icon">
										<i className="fal fa-clone"></i>
									</div>
									<span className="label">
										Categorieen samenvoegen
									</span>
								</NavLink>
								<NavLink
									activeClassName="nav-items-item_active"
									className="nav-items-item"
									to={"/admin/carts"}
								>
									<div className="icon">
										<i className="fal fa-shopping-cart"></i>
									</div>
									<span className="label">Winkelwagens</span>
								</NavLink>
							</div>
						</div>
					)}

					<div onClick={handleLogout} className="nav-items-item">
						<div className="icon">
							<i className="fal fa-sign-out-alt"></i>
						</div>
						<span className="label">Uitloggen</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Nav;

import React from "react";
import "./product-card.scss";
import { connect } from "react-redux";
import store from "../../../store";
import { toggleItem } from "../../../reducers/compare";
import { useHistory } from "react-router";
import ProductPrice from "../../ProductPrice";
import Image from "../../Image";

const ProductCard = (product: any) => {
	let price = product.info.length ? product.info[0] : {};
	let mode = product.mode;
	const history = useHistory();

	let hideButtons = false;

	if (mode === "print") {
		hideButtons = true;
	}

	const soldout = !price.is_in_stock;
	const compare_ids = product.items.map((i: any) => i.id);
	const toggleProduct = () => {
		store.dispatch(toggleItem(product));
	};

	const is_bestseller = product.info.length
		? product.info[0].is_bestseller
		: false;

	const ToggleIcon = () => (
		<div
			onClick={(e) => {
				e.stopPropagation();
				toggleProduct();
			}}
			className={`toggle-icon ${
				compare_ids.includes(product.id) ? "toggle-icon_active" : ""
			}`}
		>
			<i
				className={`fal ${
					compare_ids.includes(product.id) ? "fa-times" : "fa-plus"
				}`}
			></i>
		</div>
	);

	return (
		<div
			onClick={() => history.push(`/product/${product.id}`)}
			className={`product-card card ${product.className || ""}`}
		>
			<div className="badges">
				<Image
					src={product.vendor.logo}
					className="vendor"
					alt={product.vendor.name}
				/>
			</div>

			{soldout && !hideButtons && (
				<div className="product-card-soldout">
					<span>Uitverkocht</span>
				</div>
			)}
			{!hideButtons && <ToggleIcon />}
			<div className="thumbnail">
				<Image
					className="front"
					src={product.thumbnail}
					alt={product.title}
				/>
				{product.media.length > 1 ? (
					<Image
						src={product.media[1].link}
						className="back"
						alt={product.title}
					/>
				) : (
					false
				)}
			</div>
			<div className="product-card-info">
				<h3>{product.title}</h3>
				{product.size && <p className="size">{product.size}</p>}
				<span className="category">{product.category.title}</span>
			</div>

			<div className="product-card-price">
				<ProductPrice type="big" label="" info={price} />
			</div>
			{is_bestseller ? <div className="badge">Best verkocht</div> : false}

			{product.button ? (
				<div className="product-card-button">{product.button}</div>
			) : (
				false
			)}
		</div>
	);
};

export default connect(
	(state: any) => ({
		items: state.compare.items,
	}),
	{}
)(ProductCard);

import React from "react";
import "./category-view.scss";
import Layout from "../../components/Layout";
import Image from "../../components/Image";
import { useHistory } from "react-router";
import useFetch from "../../lib/useFetch";

const CategoryView = () => {
	const { loading, result } = useFetch("/categories");
	const history = useHistory();
	const data = result.data || [];
	return (
		<Layout title="Categorieen" type="no-menu">
			<div className="categories">
				{!loading && data.length
					? data.map((item: any) => (
							<div
								onClick={() =>
									history.push(`/products/${item.slug}`)
								}
								key={item.id + "Cat"}
								className="card"
							>
								{item.thumbnail ? (
									<Image
										src={item.thumbnail}
										alt={item.title}
										className="thumbnail"
									/>
								) : (
									false
								)}
								<h2 className="count">
									{item.products_count || ""}
								</h2>
								<p className="label">{item.title || ""}</p>
							</div>
					  ))
					: false}
			</div>
		</Layout>
	);
};

export default CategoryView;

import React, { useState } from "react";
import "./search-box.scss";
import { useHistory } from "react-router";

const SearchBox = ({ value, type }: { value: string; type?: string }) => {
	const history = useHistory();
	const [query, setQuery] = useState(value);
	return (
		<form
			onSubmit={(e) => {
				if (query) {
					history.push(`/search/${query}`);
				} else {
					history.push(`/products/alle-producten`);
				}
				e.preventDefault();
			}}
			className={`search-box ${type ? "search-box-" + type : ""}`}
		>
			<input
				type="text"
				value={query}
				onChange={(e) => setQuery(e.target.value)}
				placeholder="Zoeken..."
			/>
		</form>
	);
};

export default SearchBox;

import React from "react";
import "./loading.scss";

const Loading = ({ type }: { type?: string }) => {
	return (
		<div className={`loading ${type ? "loading-" + type : ""}`}>
			<i className="fal fa-spin fa-spinner-third"></i>
			<p>Een ogenblik geduld aub.</p>
		</div>
	);
};

export default Loading;

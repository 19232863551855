import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import "./user-detail-view.scss";
import useFetch from "../../../lib/useFetch";
import { useParams } from "react-router";
import Button from "../../../components/Button";
import api from "../../../api";

const UserDetailView = () => {
	const { id } = useParams();
	const [fetching, setFetching] = useState(false);
	const { result, loading, error } = useFetch(`/users/${id}`);
	const [value, setValue] = useState([] as number[]);
	useEffect(() => {
		if (result.data && result.data.categories) {
			setValue(result.data.categories.map((item: any) => item.id));
		}
		return () => {
			setValue([]);
		};
	}, [result]);

	const handleSave = () => {
		setFetching(true);
		if (id) {
			api.admin.user
				.allowCategories(id, value)
				.then(() => setFetching(false));
		}
	};

	return (
		<Layout
			loading={loading}
			title="Gebruiker aanpassen"
			className="user-detail-view"
			errorText={error ? `Geen gebruiker gevonden` : undefined}
		>
			<div className="card">
				<h2>Toegestaande categorieën</h2>
				<CategoryDropdown {...{ value, setValue }} />
				<Button loading={fetching} onClick={handleSave}>
					Opslaan
				</Button>
			</div>
		</Layout>
	);
};

const CategoryDropdown = ({
	value,
	setValue,
}: {
	value: number[];
	setValue: (item: number[]) => void;
}) => {
	const { result, loading } = useFetch(`/categories?all=1`);
	if (loading) {
		return <div></div>;
	}
	const { data } = result;

	const toggleItem = (id: number) => {
		if (value.includes(id)) {
			setValue(value.filter((i) => i !== id));
		} else {
			setValue([...value, id]);
		}
	};

	return (
		<div className="categories">
			<div className="categories-item">
				<span className="categories-item-label categories-item-all">
					Alles selecteren
				</span>
				<input
					onChange={() => setValue(data.map((item: any) => item.id))}
					type="checkbox"
				/>
			</div>
			{data && data.length
				? data.map((item: any) => (
						<div
							key={item.id + item.title}
							className="categories-item"
							onClick={() => toggleItem(item.id)}
						>
							<span className="categories-item-label">
								{item.title}
							</span>
							<input
								type="checkbox"
								checked={value.includes(item.id)}
							/>
						</div>
				  ))
				: false}
		</div>
	);
};

export default UserDetailView;

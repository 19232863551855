import categories from "./categories";
import products from "./products";
import vendors from "./vendors";
import auth from "./auth";
import trends from "./trends";
import user from "./admin/user";
import logs from "./admin/logs";
import adminCategories from "./admin/categories";
import cart from "./admin/carts";

export default {
	categories,
	products,
	vendors,
	auth,
	trends,
	admin: {
		user,
		logs,
		categories: adminCategories,
		cart,
	},
};

import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import "./products-graph.scss";
import Card from "../Card";

const ProductsGraph = ({ products }: { products: any[] }) => {
	let items = products;
	const days = items.length
		? items[0].info
				.reverse()
				.map((p: any) =>
					moment(p.date, "YYYY-MM-DD").format("DD-MM-YYYY")
				)
		: [];
	return (
		<Card title="Prijs verloop per dag" className="products-graph">
			<HighchartsReact
				highcharts={Highcharts}
				options={{
					title: {
						text: "",
					},
					xAxis: {
						categories: days,
					},
					series: items.reverse().map((item: any) => ({
						name: item.title,
						data: item.info.map((p: any) => p.price),
					})),
				}}
			/>
		</Card>
	);
};

export default ProductsGraph;

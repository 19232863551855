import React from "react";
import "./button.scss";
import loader from "./loader.svg";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

type ButtonProps = {
	children: any;
	loading?: boolean;
	type?: string;
	onClick?: (e: any) => void;
	to?: string;
	href?: string;
	newPage?: boolean;
};

const Button = ({
	children,
	loading,
	type,
	onClick,
	to,
	href,
	newPage,
}: ButtonProps) => {
	const className = `button ${type ? "button-" + type : ""} ${
		loading ? "button-loading" : ""
	}`;

	if (href) {
		return (
			<a
				className={className}
				target="_blank"
				rel="noopener noreferrer"
				href={href}
			>
				{loading ? <img src={loader} alt="Loading" /> : children}
			</a>
		);
	}

	if (to) {
		return (
			<Link
				target={newPage ? "_blank" : ""}
				className={className}
				to={to}
			>
				{loading ? <img src={loader} alt="Loading" /> : children}
			</Link>
		);
	}

	return (
		<div onClick={onClick ? onClick : () => {}} className={className}>
			{loading ? <img src={loader} alt="Loading" /> : children}
		</div>
	);
};

export default Button;

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./login-view.scss";
import Input from "../../../components/Form/Input";
import Button from "../../../components/Button";
import Background from "../../../components/Background";
import { NavLink } from "react-router-dom";
import store from "../../../store";
import { setUser as setReduxUser } from "../../../reducers/user";
import api from "../../../api";
import Loading from "../../../components/Loading";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const CardVariants = {
	start: {
		opacity: 0,
		y: -200,
		scale: 1.2,
	},
	end: {
		opacity: 1,
		y: 0,
		scale: 1,
	},
};

const LoginView = () => {
	const auth = useSelector((state: any) => state.user);
	const history = useHistory();
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [user, setUser] = useState({
		email: "",
		password: "",
	});

	const handleRedirect = () => history.push("/categories");
	useEffect(() => {
		if (auth.id && auth.is_confirmed) {
			history.push("/categories");
		} else {
			setLoading(false);
		}
		return () => {
			setLoading(true);
		};
	}, [auth, history]);

	const handleLogin = () => {
		if (!user.email || !user.password) {
			setError("Vul alle velden in");
			return;
		}
		setError("");
		setFetching(true);
		api.auth.login(user).then((data: any) => {
			if (!data.id) {
				setFetching(false);
				setError("Er is iets misgegaan.");
				setUser({
					email: "",
					password: "",
				});
				return;
			}
			localStorage.setItem("token", data.token);
			store.dispatch(setReduxUser(data));
			setFetching(false);
			if (auth.is_confirmed) {
				handleRedirect();
			}
		});
	};

	if (loading) {
		return <Loading type="black" />;
	}

	return (
		<Background className="login-view">
			<div>
				<motion.div
					variants={CardVariants}
					initial="start"
					animate="end"
					className="card"
					transition={{
						type: "spring",
						stiffness: 260,
						damping: 100,
					}}
				>
					<h1>Inloggen</h1>
					{error && <p className="alert">{error}</p>}
					<Input
						onChange={(text) => setUser({ ...user, email: text })}
						value={user.email}
						placeholder="E-mail"
					/>
					<Input
						onChange={(text) =>
							setUser({ ...user, password: text })
						}
						value={user.password}
						placeholder="Wachtwoord"
						type="password"
					/>
					<div className="buttons">
						<Button to="/forgot" type="gray">
							Wachtwoord vergeten
						</Button>
						<Button loading={fetching} onClick={handleLogin}>
							Inloggen
						</Button>
					</div>
				</motion.div>
				<motion.div
					variants={CardVariants}
					initial="start"
					animate="end"
					className="card card-register"
					transition={{
						type: "spring",
						stiffness: 260,
						damping: 100,
						delay: 0.1,
					}}
				>
					<p className="register">
						Heeft u nog geen account? klik{" "}
						<NavLink to="/register">hier</NavLink> om u aan te
						melden
					</p>
				</motion.div>
			</div>
		</Background>
	);
};

export default LoginView;

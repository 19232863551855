import React, { useState, useEffect } from "react";
import useFetch from "../../lib/useFetch";
import Dropdown from "../Dropdown";

const VendorDropDown = ({
	slug,
	value,
	setValue,
	emptyPlaceholder,
}: {
	slug?: string;
	value: number | null;
	setValue: (id: number | null) => void;
	emptyPlaceholder?: string;
}) => {
	let query = slug ? `/vendors?slug=${slug}` : "/vendors";
	const { result, loading } = useFetch(query);
	const [options, setOptions] = useState([] as any[]);

	useEffect(() => {
		if (!loading) {
			const items = [
				{ id: null, text: emptyPlaceholder || "Alle leveranciers" },
				...result.data.map((vendor: any) => ({
					id: vendor.id,
					text: vendor.name,
				})),
			];
			setOptions(items);
		}
	}, [loading, result, emptyPlaceholder]);

	return <Dropdown {...{ value, setValue, options }} />;
};

export default VendorDropDown;

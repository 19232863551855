import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useFetch = (route: string, type?: string, body?: any) => {
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState({} as any);
	const [error, setError] = useState(null);

	const handleFetch = async () => {
		setLoading(true);
		setResult({});
		const getUrl = (r: string) => {
			if (type === "node") {
				return process.env.REACT_APP_NODE_URL + r;
			}
			return r;
		};

		axios
			.get(getUrl(route), body ? { params: body } : {})
			.then((res: any) => {
				setResult(res.data);
				setLoading(false);
			})
			.catch((e: any) => {
				setError(e);
				setResult({
					data: [],
				});
				setLoading(false);
			});
	};

	useEffect(() => {
		handleFetch();
		return () => {
			setResult({});
			setLoading(true);
			setError(null);
		};
	}, [route]);

	return {
		loading,
		result,
		error,
		refetch: handleFetch,
		setResult,
	};
};

export default useFetch;

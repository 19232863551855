import React, { useState } from "react";
import "./products.scss";
import { useParams, useHistory } from "react-router";
import useFetch from "../../lib/useFetch";
import { NavLink } from "react-router-dom";
import ProductCard from "../Compare/ProductCard";
import VendorDropDown from "../VendorDropDown";

const Products = ({ search }: { search?: string }) => {
	const history = useHistory();
	const { category, page } = useParams();
	const [vendor, setVendor] = useState(null as number | null);

	let query = `/products?page=${page || 1}`;
	if (search) {
		query += `&q=${search}`;
	}
	if (category !== "alle-producten") {
		query += `&slug=${category}`;
	}
	if (vendor) {
		query += `&vendor=${vendor}`;
	}
	const { result, error, loading } = useFetch(query);

	if (error) {
		return (
			<div className="error">
				<h2>Something went wrong!</h2>
			</div>
		);
	}

	let { data } = result;
	data = data || [];
	return (
		<>
			<div className="sort">
				<div className="sort-vendor">
					<VendorDropDown
						value={vendor}
						setValue={(v) => {
							history.push(`/products/${category}/1`);
							setVendor(v);
						}}
						slug={category !== "alle-producten" ? category : ""}
					/>
					{result.meta && result.meta.to ? (
						<div className="total">
							{result?.meta?.to || ""} / {result?.meta?.total}
						</div>
					) : (
						false
					)}
				</div>

				<Pagination
					meta={result.meta || { current_page: 1, last_page: 99 }}
					createLink={(p: number) => `/products/${category}/${p}`}
				/>
			</div>

			<div className="products">
				{data.length ? (
					data.map((item: any) => (
						<ProductCard
							key={item.id + "product index"}
							{...{ ...item }}
						/>
					))
				) : !loading ? (
					<div className="products-empty">
						<h2>Geen producten gevonden</h2>
					</div>
				) : (
					false
				)}
			</div>
			<Pagination
				meta={result.meta || { current_page: 1, last_page: 99 }}
				createLink={(p: number) => `/products/${category}/${p}`}
			/>
		</>
	);
};

const Pagination = ({
	createLink,
	meta,
}: {
	createLink: (p: number) => void;
	meta: {
		current_page: number;
		last_page: number;
	};
}) => {
	const renderControls = () => {
		let controls = [];
		let start =
			meta.current_page === 2 || meta.current_page === 1
				? meta.current_page
				: meta.current_page - 2;
		const max = 5;
		for (
			let i = start;
			i <= meta.last_page && controls.length !== max;
			i++
		) {
			controls.push(i);
		}
		return (
			<div className={"controls"}>
				{controls.map((i) => (
					<NavLink key={"page" + i} to={createLink(i)}>
						{i}
					</NavLink>
				))}
			</div>
		);
	};

	return (
		<div className="pagination">
			<NavLink
				className={`pagination-button ${
					meta.current_page === 1 ? "inactive" : ""
				}`}
				to={createLink(meta.current_page - 1)}
			>
				Vorige
			</NavLink>

			{renderControls()}
			<NavLink
				className={`pagination-button ${
					meta.current_page === meta.last_page ? "inactive" : ""
				}`}
				to={createLink(meta.current_page + 1)}
			>
				Volgende
			</NavLink>
		</div>
	);
};

export default Products;

import React from "react";
import Lottie from "react-lottie";
import "./not-found.scss";
import notfound from "../../assets/lottie/not-found.json";

const NotFound = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: notfound,
	};

	return (
		<div className="not-found">
			<Lottie
				options={defaultOptions}
				width={400}
				isStopped={false}
				isPaused={false}
			/>
		</div>
	);
};

export default NotFound;

import axios from "axios";

type Filter = {
	vendor: number;
	category: number;
	is_bestseller: boolean;
};

export default {
	listAll: (page: number = 1, limit: number = 18, filter: Filter) => {
		let query = `/products?page=${page}&limit=${limit}`;
		if (filter.vendor) {
			query += `&vendor=${filter.vendor}`;
		}

		if (filter.category) {
			query += `&category=${filter.category}`;
		}

		if (filter.is_bestseller) {
			query += `&is_bestseller=1`;
		}

		return axios.get(query);
	},
	list: (slug: string, page: number = 1, vendor: number | null = null) => {
		let query = `/products?page=${page}&slug=${slug}`;
		if (vendor) {
			query += `&vendor=${vendor}`;
		}
		return axios.get(query);
	},
	listBestsellers: ({ page, vendor }: { page: number; vendor?: number }) => {
		let query = `/products?page=${page}&is_bestseller=1`;
		if (vendor) {
			query += `&vendor=${vendor}`;
		}
		return axios.get(query);
	},
};

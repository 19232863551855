import React from "react";
import "./layout.scss";
import Nav from "./Nav";
import Loading from "../Loading";
import CompareNav from "../CompareNav";
import { useHistory } from "react-router";
import NotFound from "../NotFound";
type LayoutProps = {
	children: any;
	title?: string;
	button?: any;
	type?: string;
	loading?: boolean;
	emptyMessage?: string;
	goBack?: boolean;
	className?: string;
	errorText?: string;
};

const Layout = ({
	children,
	title,
	button,
	type,
	loading,
	emptyMessage,
	goBack,
	className,
	errorText,
}: LayoutProps) => {
	const history = useHistory();
	return (
		<div className={`layout ${type ? "layout-" + type : ""}`}>
			<Nav />
			<div
				className={`${
					type === "no-spacing" ? "container-fluid" : "container"
				}`}
			>
				{errorText ? (
					<div className="layout-content layout-error">
						<NotFound />
						<h1>Oeps! Er is iets misgegaan</h1>
						<h2>{errorText}</h2>
					</div>
				) : (
					<div className={`layout-content ${className || ""}`}>
						<CompareNav />
						{title ? (
							<div className="layout-content-head">
								<div className="layout-content-head_title">
									{goBack && (
										<i
											onClick={() => history.goBack()}
											className="fal fa-arrow-left"
										></i>
									)}
									<h1>{title}</h1>
								</div>
								{button || ""}
							</div>
						) : (
							false
						)}
						{loading ? (
							<div className="layout-loading">
								<Loading />
							</div>
						) : emptyMessage ? (
							<div className="layout-empty">
								<p>{emptyMessage}</p>
							</div>
						) : (
							children
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Layout;

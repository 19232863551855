import React, { useEffect } from "react";
import Dropdown from "../../components/Dropdown";
import CreateCart from "../../components/CreateCart";
import useFetch from "../../lib/useFetch";
import Button from "../../components/Button";
import ProductPrice from "../../components/ProductPrice";
import api from "../../api";
import _ from "lodash";

const Cart = ({
	cart,
	setCart,
}: {
	cart: any;
	setCart: (cart: any) => void;
}) => {
	const carts = useFetch("/carts");
	if (cart.slug == "") {
		let data = carts?.result?.data || [];
		let options = data.map((item: any) => ({
			id: item.slug,
			text: item.name,
		}));
		return (
			<div className="cart cart-empty">
				<div className="cart-wrapper">
					<Dropdown
						placeholder="Kies een winkelwagen"
						options={options}
						value={cart}
						setValue={(slug) => {
							if (slug) {
								let found = data.find(
									(c: any) => c.slug === slug
								);
								localStorage.setItem("selected-cart", slug);
								setCart(found);
							}
						}}
					/>
				</div>
				<CreateCart
					onCreate={(cart: any) => {
						carts.setResult({
							...carts.result,
							data: [...carts.result.data, cart],
						});
						localStorage.setItem("selected-cart", cart.slug);
						setCart(cart);
					}}
				/>
			</div>
		);
	}

	return (
		<CartList
			onClear={() =>
				setCart({
					slug: "",
					id: 0,
					product_ids: [],
				})
			}
			ids={cart.product_ids}
			onChange={setCart}
			slug={cart.slug}
		/>
	);
};

const CartList = ({
	slug,
	onClear,
	onChange,
	ids,
}: {
	slug: string;
	onClear: () => void;
	onChange: (i: any) => void;
	ids: number[];
}) => {
	const { result, loading, setResult, refetch } = useFetch(`/carts/${slug}`);
	let cart = result?.data || {};
	let products = cart.products || [];

	useEffect(() => {
		refetch();
	}, [ids]);

	const handleAddToCart = (product_id: number) => {
		let products = _.xor(cart.product_ids, [product_id]);
		api.admin.cart.patch(cart.id, products).then(({ data }) => {
			setResult(data);
			onChange(data.data);
		});
	};

	return (
		<div className="cart">
			<div className="cart-name">
				<h2>{cart.name || ""}</h2>
				<Button type="gray" onClick={onClear}>
					<i className="fal fa-times"></i>
				</Button>
			</div>
			<div className="cart-wrapper">
				<ul className="cart-list">
					{products.map((product: any) => (
						<li
							className="cart-list-item"
							key={product.id + " - " + product.title}
						>
							<img src={product.thumbnail} alt="" />
							<div className="info">
								<p className="info-title">{product.title}</p>
								<ProductPrice info={product.info[0]} />
								<span
									className="clear"
									onClick={() => handleAddToCart(product.id)}
								>
									Verwijderen
								</span>
							</div>
						</li>
					))}
				</ul>
			</div>
			<div className="cart-button">
				<Button newPage to={`/collecties/${slug}`}>
					Bekijk winkelwagen
				</Button>
			</div>
		</div>
	);
};

export default Cart;

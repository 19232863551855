import axios from "axios";

type RegisterProps = {
	first_name: string;
	affix?: string | null;
	last_name: string;
	email: string;
	company: string;
	password: string;
};

type LoginProps = {
	email: string;
	password: string;
};

export default {
	login: async (user: LoginProps) => {
		try {
			let { data } = await axios.post("/auth/login", user);
			return data;
		} catch (error) {
			return { id: null };
		}
	},
	profile: async () => {
		try {
			let { data } = await axios.get("/auth/profile");
			return data;
		} catch (error) {
			return { id: null };
		}
	},
	register: async (register: RegisterProps) => {
		return await axios.post("/auth/register", register as any);
	},
	requestPassword: async (email: string) => {
		return axios.post("/auth/request-password", { email });
	},
	findPassword: async (token: string) => {
		return axios.get(`/auth/find-password/${token}`);
	},
	resetPassword: async ({
		email,
		password,
		token,
	}: {
		email: string;
		password: string;
		token: string;
	}) => {
		return axios.post("/auth/reset-password", { email, password, token });
	},
};

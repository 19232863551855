import { createAction, handleActions } from "redux-actions";

export const getUser = createAction("GET_USER");
export const setUser = createAction("SET_USER");
export const resetUser = createAction("RESET_USER");

export type UserState = {
	id: number | null;
	first_name: string;
	affix: string | null;
	last_name: string;
	full_name: string;
	company: string;
	email: string;
	is_admin: boolean;
	is_confirmed: boolean;
	token?: string;
};

const emptyUserState = {
	id: null,
	first_name: "",
	affix: "",
	last_name: "",
	full_name: "",
	company: "",
	email: "",
	is_admin: false,
	is_confirmed: false,
	token: "",
} as UserState;

const userReducer = handleActions(
	{
		GET_USER: (state: any, action: any) => {
			return {
				...state,
			};
		},
		SET_USER: (state: any, action: any) => {
			let user = action.payload;
			if (user && user.id) {
				return { ...state, ...user };
			}
			return {
				...state,
			};
		},
		RESET_USER: (state: any, action: any) => {
			return {
				...emptyUserState,
			};
		},
	},
	{
		...emptyUserState,
	}
);
export default userReducer;

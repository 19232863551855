import React, { useState } from "react";
import "./vendor-products-view.scss";
import Layout from "../../components/Layout";
import useFetch from "../../lib/useFetch";
import ProductCard from "../../components/Compare/ProductCard";
import Button from "../../components/Button";

const VendorProductsView = (props: any) => {
	const [fetching, setFetching] = useState(false);
	const id = props.match.params.id;
	const { loading, result } = useFetch(`/vendors/${id}`);

	const handleExport = () => {
		setFetching(true);
	};

	return (
		<Layout
			button={
				<Button onClick={handleExport} loading={fetching}>
					Download als excel
				</Button>
			}
			title="Producten"
		>
			<div className="vendor-products-view">
				{loading ? (
					<div></div>
				) : result.data.length ? (
					<div className="grid">
						{result.data.map((item: any) => (
							<ProductCard
								{...{
									...item,
									key: `${item.id} ${item.title}`,
									mode: "print",
								}}
							/>
						))}
					</div>
				) : (
					false
				)}
			</div>
		</Layout>
	);
};

export default VendorProductsView;

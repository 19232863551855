import React, { useEffect } from "react";
import useFetch from "../../../lib/useFetch";

const LogHead = ({
	setActive,
	active,
}: {
	setActive: (key: string) => void;
	active: string;
}) => {
	const { result } = useFetch("/", "node");
	const configurations =
		result.configurations && result.configurations
			? result.configurations
			: [];

	useEffect(() => {
		setActive(configurations[0]);
	}, [configurations, setActive]);
	return (
		<div className="logs-head">
			{configurations.map((item: string) => (
				<span
					onClick={() => setActive(item)}
					className={`logs-head-item ${
						item === active ? "logs-head-item_active" : ""
					}`}
					key={item}
				>
					{item}
				</span>
			))}
		</div>
	);
};

export default LogHead;

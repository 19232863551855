import React from "react";
import "./confirm-view.scss";
import Button from "../../components/Button";
import store from "../../store";
import { resetUser } from "../../reducers/user";

type ConfirmViewProps = {
	full_name: string;
};

const ConfirmView = ({ full_name }: ConfirmViewProps) => {
	const handleLogout = async () => {
		await localStorage.setItem("token", "");
		store.dispatch(resetUser());
	};

	return (
		<div className="confirm-view">
			<h1>Welkom {full_name || ""}</h1>
			<p>
				Uw account is nog niet goedgekeurd. U ontvangt een e-mail
				wanneer uw account is goedgekeurd.
			</p>
			<Button type="white" onClick={handleLogout}>
				Uitloggen
			</Button>
		</div>
	);
};

export default ConfirmView;

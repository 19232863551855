import React, { useState } from "react";
import Layout from "../../../components/Layout";
import "./merge-categories-view.scss";
import useFetch from "../../../lib/useFetch";
import Dropdown from "../../../components/Dropdown";
import Button from "../../../components/Button";
import api from "../../../api";

const MergeCategoryView = () => {
	const { result, loading, refetch } = useFetch(`/categories?all=1`);
	const [fetching, setFetching] = useState(false);
	const [target, setTarget] = useState(null as any);
	const [values, setValues] = useState([] as number[]);
	const { data } = result;

	const toggleItem = (id: number) => {
		if (values.includes(id)) {
			setValues(values.filter((i) => i !== id));
		} else {
			setValues([...values, id]);
		}
	};

	const handleSave = () => {
		if (!target || !values.length) {
			return;
		}
		setFetching(true);
		api.admin.categories
			.merge(target, values)
			.then((res) => {
				refetch().then(() => {
					setFetching(false);
				});
			})
			.finally(() => {
				setValues([]);
				setTarget(null);
			});
	};

	return (
		<Layout loading={loading} title="Categorieen samenvoegen">
			<div className="merge-categories-view">
				<div className="card">
					<h2>Selecteer categorieen om te vervangen</h2>
					<div className="card-grid">
						{data &&
							data.length &&
							data.map((item: any) => (
								<div
									onClick={() => toggleItem(item.id)}
									key={item.id + item.title}
									className="item"
								>
									<span className="item-label">
										{item.title}
									</span>
									<input
										onChange={() => toggleItem(item.id)}
										checked={values.includes(item.id)}
										type="checkbox"
									/>
								</div>
							))}
					</div>
				</div>
				<div className="dropdown-wrapper">
					<h2>Categorie om in samen te voegen</h2>
					<Dropdown
						placeholder="Selecteer categorie"
						value={target}
						setValue={setTarget}
						options={
							data && data.length
								? data.map((item: any) => ({
										id: item.id,
										text: item.title,
								  }))
								: []
						}
					/>
					<Button loading={fetching} onClick={handleSave}>
						Samenvoegen
					</Button>
				</div>
			</div>
		</Layout>
	);
};

export default MergeCategoryView;

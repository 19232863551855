import React, { useState } from "react";
import Layout from "../../../components/Layout";
import "./carts-view.scss";
import useFetch from "../../../lib/useFetch";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import { NavLink } from "react-router-dom";
import Input from "../../../components/Form/Input";
import api from "../../../api";

const CartsView = () => {
	const [name, setName] = useState("");
	const [fetching, setFetching] = useState(false);

	const { loading, result, setResult } = useFetch("/carts");

	const handleSubmitName = () => {
		if (!name) {
			return;
		}
		setFetching(true);
		api.admin.cart
			.create(name)
			.then(({ data }: any) => {
				setResult({
					...result,
					data: [...result.data, data.data],
				});
				setName("");
			})
			.catch((e) => {
				console.log({
					e,
				});
				alert("Er is iets misgegaan");
			})
			.finally(() => setFetching(false));
	};

	const handleDelete = (id: number) => {
		api.admin.cart.delete(id).then(() => {
			setResult({
				...result,
				data: result.data.filter((i: any) => i.id !== id),
			});
		});
	};

	return (
		<Layout loading={loading} title="Winkelwagens">
			<div className="carts-index-view">
				<div className="carts">
					{result.data && result.data.length
						? result.data.map((item: any) => (
								<Card key={item.slug}>
									<div className="carts-item">
										<div className="carts-item-info">
											<h2>{item.name}</h2>
											<NavLink
												to={`/collecties/${item.slug}`}
											>
												{window.location.origin}
												/collecties/
												{item.slug}
											</NavLink>
											<p>
												Aantal producten:{" "}
												<strong>
													{item.products_count}
												</strong>
											</p>
										</div>
										<Button
											onClick={() =>
												handleDelete(item.id)
											}
											type="transparent"
										>
											Verwijder lijst
										</Button>
										<Button
											to={`/admin/carts/${item.slug}`}
										>
											Producten aanpassen
										</Button>
									</div>
								</Card>
						  ))
						: false}
				</div>
				<div className="cart-form">
					<Card title="Winkelwagen aanmaken">
						<Input
							placeholder="Naam"
							value={name}
							onChange={setName}
						/>
						<Button loading={fetching} onClick={handleSubmitName}>
							Winkelwagen aanmaken
						</Button>
					</Card>
				</div>
			</div>
		</Layout>
	);
};

export default CartsView;

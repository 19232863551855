import React from "react";
import "./price-compare-view.scss";
import Layout from "../../components/Layout";
import ProductCard from "../../components/Compare/ProductCard";
import { connect } from "react-redux";
import ProductsGraph from "../../components/ProductsGraph";

const PriceCompareView = (props: any) => {
	const items = props.compare.items;

	if (!items.length) {
		return (
			<Layout
				emptyMessage="Kies eerst producten om te vergelijken"
				title="Prijs vergelijken"
			>
				<span></span>
			</Layout>
		);
	}

	return (
		<Layout type="no-menu" title="Prijs vergelijken">
			<div className="price-compare-view">
				<ProductsGraph products={items} />
				{items.map((product: any) => (
					<ProductCard
						{...{
							...product,
							key: product.id + product.title + "card",
						}}
					/>
				))}
			</div>
		</Layout>
	);
};

export default connect(
	(state: any) => ({
		compare: state.compare,
	}),
	{}
)(PriceCompareView);

import React from "react";
import Layout from "../../components/Layout";
import VendorList from "../../components/VendorList";
import Statistics from "../../components/Statistics";

const OverViewView = () => {
	return (
		<Layout>
			<Statistics />
			<VendorList />
		</Layout>
	);
};

export default OverViewView;

import React from "react";
import "./vendor-list.scss";
import useFetch from "../../lib/useFetch";
import { useHistory } from "react-router";
const VendorList = () => {
	const { loading, result } = useFetch("/vendors");
	const history = useHistory();
	return (
		<div className="vendor-list">
			<h2>Leveranciers</h2>
			{loading ? (
				<div className="vendor-list_loading">
					<i className="fal fa-spin fa-spinner-third"></i>
				</div>
			) : (
				<div className="vendor-list-grid">
					{result.data.map((vendor: any) => (
						<div
							key={`vendor-${vendor.id}`}
							onClick={() => history.push(`/vendor/${vendor.id}`)}
							className="card"
						>
							<div className="logo">
								<img src={vendor.logo} alt="" />
							</div>
							<p className="label">{vendor.name}</p>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default VendorList;

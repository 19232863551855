import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./password-forgot-view.scss";
import Input from "../../../components/Form/Input";
import Button from "../../../components/Button";
import Background from "../../../components/Background";
import api from "../../../api";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
const CardVariants = {
	start: {
		opacity: 0,
		y: -200,
		scale: 1.2,
	},
	end: {
		opacity: 1,
		y: 0,
		scale: 1,
	},
};
const PasswordForgotView = () => {
	const { token } = useParams();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [fetching, setFetching] = useState(false);
	const [status, setStatus] = useState("");

	const handleRequestPassword = () => {
		if (!email) {
			setStatus("Vul uw email in");
			return;
		}
		setFetching(true);
		api.auth
			.requestPassword(email)
			.then((res) => {
				setFetching(false);
				setEmail("");
				setStatus(
					"U ontvangt een e-mail waarmee u uw wachtwoord kan wijzigen"
				);
			})
			.catch(() => {
				setFetching(false);
				setStatus("Er is iets misgegaan, probeer het later opnieuw.");
			});
	};

	useEffect(() => {
		if (token) {
			setFetching(true);
			api.auth
				.findPassword(token)
				.then((res: any) => {
					setEmail(res.data.email);
				})
				.finally(() => setFetching(false));
		}
	}, [token]);

	const handleResetPassword = () => {
		if (!password || !repeatPassword || password !== repeatPassword) {
			setStatus("Vul alle velden in en zorg dat ze overeen komen.");
			return;
		}
		if (!email) {
			setStatus("Er is iets misgegaan.");
			return;
		}
		setFetching(true);
		api.auth
			.resetPassword({ email, password, token })
			.then(() => {
				setFetching(false);
				setStatus(
					"Uw wachtwoord is gewijzigd u kunt nu weer inloggen."
				);
				setPassword("");
				setRepeatPassword("");
			})
			.catch(() => setStatus("Er is iets misgegaan."))
			.finally(() => setFetching(false));
	};

	if (token) {
		if (!email && fetching) {
			return <Loading type="black" />;
		}

		if (!email && !fetching) {
			return (
				<Background className="password-forgot-view">
					<motion.div
						variants={CardVariants}
						initial="start"
						animate="end"
						className="card"
						transition={{
							type: "spring",
							stiffness: 260,
							damping: 100,
						}}
					>
						<h1>Token is verlopen</h1>
					</motion.div>
				</Background>
			);
		}

		return (
			<Background className="password-forgot-view">
				<motion.div
					variants={CardVariants}
					initial="start"
					animate="end"
					className="card"
					transition={{
						type: "spring",
						stiffness: 260,
						damping: 100,
					}}
				>
					<h1>Wachtwoord wijzigen</h1>
					{status && <p>{status}</p>}
					<Input
						onChange={setPassword}
						value={password}
						type="password"
						placeholder="Wachtwoord"
					/>
					<Input
						onChange={setRepeatPassword}
						value={repeatPassword}
						type="password"
						placeholder="Wachtwoord herhalen"
					/>
					<div className="buttons">
						<Button to="/" type="gray">
							Annuleren
						</Button>
						<Button
							onClick={handleResetPassword}
							loading={fetching}
						>
							Wijzigen
						</Button>
					</div>
				</motion.div>
			</Background>
		);
	}

	return (
		<Background className="password-forgot-view">
			<motion.div
				variants={CardVariants}
				initial="start"
				animate="end"
				className="card"
				transition={{
					type: "spring",
					stiffness: 260,
					damping: 100,
				}}
			>
				<h1>Wachtwoord vergeten {token}</h1>
				{status && <p>{status}</p>}
				<Input onChange={setEmail} value={email} placeholder="E-mail" />
				<div className="buttons">
					<Button to="/" type="gray">
						Annuleren
					</Button>
					<Button onClick={handleRequestPassword} loading={fetching}>
						Aanvragen
					</Button>
				</div>
			</motion.div>
		</Background>
	);
};

export default PasswordForgotView;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./firebase/firebase";
import "./base.scss";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import Routes from "./Routes";

const App = () => {
    return (
        <Provider store={store}>
            <Routes />
        </Provider>
    );
};

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();

import React, { useState } from "react";

type ImageProps = {
	alt?: string;
	src: any;
	className?: string;
	[key: string]: any;
};

const Image = (props: ImageProps) => {
	const [source, setSource] = useState(props.src);
	return (
		<img
			src={source}
			alt={props.alt || ""}
			onError={() =>
				setSource(require("../../assets/images/placeholder.jpg"))
			}
			className={props.className || ""}
			{...props}
		/>
	);
};

export default Image;

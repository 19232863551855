import axios from "axios";
export default {
	create: (name: string) => {
		return axios.post("/carts", { name });
	},
	delete: (id: number) => {
		return axios.delete(`/carts/${id}`);
	},
	patch: (id: number, products: number[]) => {
		return axios.patch(`/carts/${id}`, { products });
	},
	show: (slug: string) => {
		return axios.get(`/carts/${slug}`).then(({ data }) => data.data);
	},
};
